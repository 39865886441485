<template>
    <div class="membership-img-container p-relative">
        <div class="img-wrapper">
            <img
                v-if="selectedMembership === 'mf_3month' && me.gender == 0"
                :src="require('@/assets/images/membership/marry-fit-male-6.png')"
                alt=""
            />
            <img
                v-else-if="selectedMembership === 'mf_3month' && me.gender == 1"
                :src="require('@/assets/images/membership/marry-fit-female-6.png')"
                alt=""
            />
            <img v-else :src="require('@/assets/images/membership/marry-fit-plus-6.png')" alt="" />
        </div>
        <div v-if="selectedMembership === 'mf_3month' && me.gender === 1" class="carousel-container">
            <swiper ref="mySwiper" :options="swiperOptions" class="swiper-container">
                <swiper-slide v-for="photo in photos" :key="photo.id">
                    <img style="width: 75%" :src="photo.url" alt="" />
                </swiper-slide>

                <div slot="button-prev" class="swiper-button-prev swiper-button-white">
                    <img
                        :src="require('@/assets/images/swiper-arrow-back.png')"
                        alt="스와이프 버튼"
                        class="btn-img left"
                    />
                </div>
                <div slot="button-next" class="swiper-button-next">
                    <img :src="require('@/assets/images/swiper-arrow-next.png')" alt="스와이프 버튼" class="btn-img" />
                </div>
                <!-- <div slot="pagination" class="swiper-pagination"></div> -->
            </swiper>
        </div>
    </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
    name: 'MembershipDetail6',
    props: ['selectedMembership'],
    components: {
        swiper,
        swiperSlide,
    },
    data: () => ({
        photos: [],
        swiperOptions: {
            initialSlide: 0,
            // pagination: {
            //     el: '.swiper-pagination',
            //     clickable: true,
            //     renderBullet: (index, className) => {
            //         return '<span class="' + className + '"></span>'
            //     },
            // },
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            grabCursor: true,
        },
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        products() {
            return this.$store.getters.products
        },
        photoList() {
            const photoList = [
                {
                    id: 1,
                    url: require('@/assets/images/membership/marry-fit-carousel/female-5.png'),
                },
                {
                    id: 2,
                    url: require('@/assets/images/membership/marry-fit-carousel/female-6.png'),
                },
                {
                    id: 3,
                    url: require('@/assets/images/membership/marry-fit-carousel/female-7.png'),
                },
                {
                    id: 4,
                    url: require('@/assets/images/membership/marry-fit-carousel/female-8.png'),
                },
                {
                    id: 5,
                    url: require('@/assets/images/membership/marry-fit-carousel/female-9.png'),
                },
                {
                    id: 6,
                    url: require('@/assets/images/membership/marry-fit-carousel/female-10.png'),
                },
                {
                    id: 7,
                    url: require('@/assets/images/membership/marry-fit-carousel/female-11.png'),
                },
                {
                    id: 8,
                    url: require('@/assets/images/membership/marry-fit-carousel/female-12.png'),
                },
            ]

            return photoList
        },
    },
    mounted() {
        this.photos = this.photoList
    },
}
</script>

<style scoped lang="scss">
.carousel-container {
    width: 100%;
    max-width: 515px;
    position: absolute;
    bottom: 4rem;
    justify-content: center;
    padding: 0 6px;
    z-index: 1;

    .swiper-slide {
        @include center;
    }
    .swiper-button-next,
    .swiper-button-prev {
        $size: 1.2rem;
        width: $size;
        height: $size;
        opacity: 0.3;
        background-image: none;

        .btn-img {
            width: 100%;
        }

        &::after {
            content: none;
        }
    }
}
</style>
